<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 16%">Date Time</th>
                        <th class="report-div-table-col" style="width: 10%">Location</th>
                        <th class="report-div-table-col" style="width: 10%">Item</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Price</th>
                        <th class="report-div-table-col" style="width: 16%">Status</th>
                        <th class="report-div-table-col" style="width: 10%">Card Type</th>
                        <th class="report-div-table-col" style="width: 10%">Card Number</th>
                        <th class="report-div-table-col" style="width: 16%">Card Holder Name</th>
                        <th class="report-div-table-col" style="width: 16%">Transaction Result</th>
                        <th class="report-div-table-col" style="width: 16%">Response Text</th>
                        <th class="report-div-table-col" style="width: 16%">Reference Number</th>
                        <th class="report-div-table-col" style="width: 12%">Authorization Code</th>                        
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.location }}</td>
                        <td class="report-div-table-col">{{ row.item }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.amountDisplay }}</td>
                        <td class="report-div-table-col">{{ row.status }}</td>
                        <td class="report-div-table-col">{{ row.cardType }}</td>
                        <td class="report-div-table-col">{{ row.cardNumber }}</td>
                        <td class="report-div-table-col">{{ row.cardHolderName }}</td>
                        <td class="report-div-table-col">{{ row.transactionResult }}</td>
                        <td class="report-div-table-col">{{ row.responseText }}</td>
                        <td class="report-div-table-col">{{ row.referenceNumber }}</td>
                        <td class="report-div-table-col">{{ row.authorizationCode }}</td>                        
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from '@/common/helper'

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Card Reader Logs");
        const reportfilename = ref("CardReaderLogs");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.cardReadLogs(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time : item.timestampDisplay.toExcelSafe(),
                    Location : item.location?.toExcelSafe(),
                    Item : item.item?.toExcelSafe(),
                    Price : item.amount,
                    Status : item.status?.toExcelSafe(),
                    Card__Type : item.cardType?.toExcelSafe(),
                    Card__Number : item.cardNumber?.toExcelSafe(),
                    Card__Holder__Name : item.cardHolderName?.toExcelSafe(),
                    Transaction__Result : item.transactionResult?.toExcelSafe(),
                    Response__Text : item.responseText?.toExcelSafe(),
                    Reference__Number : item.referenceNumber?.toExcelSafe(),
                    Authorization__Code : item.authorizationCode?.toExcelSafe(),
                };                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };        

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 45,
                pField2: "left",

                xField3: 58,
                pField3: "left",

                xField4: 91,
                pField4: "right",

                xField5: 95,
                pField5: "left",

                xField6: 119,
                pField6: "left",

                xField7: 137,
                pField7: "left",

                xField8: 163,
                pField8: "left",

                xField9: 196,
                pField9: "left",

                xField10: 219,
                pField10: "left",

                xField11: 242,
                pField11: "left",

                xField12: 265,
                pField12: "left",
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time : item.timestampDisplay,
                    Location : item.location,
                    Item : item.item,
                    Price : item.amountDisplay,
                    Status : item.status,
                    Card__Type : item.cardType,
                    Card__Number : item.cardNumber,
                    Card__Holder__Name : item.cardHolderName,
                    Transaction__Result : item.transactionResult,
                    Response__Text : item.responseText,
                    Reference__Number : item.referenceNumber,
                    Authorization__Code : item.authorizationCode,
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape", -3);
        };        

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,            
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
